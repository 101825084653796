import React, { Component } from "react"
import styles from "../../css/items.module.css"
import Event from "./Event"
import Title from "../Title"

export default class EventList extends Component {
  state = {
    events: [],
    sortedEvents: [],
  }
  componentDidMount() {
    this.setState({
      events: this.props.events.edges,
      sortedEvents: this.props.events.edges,
    })
  }

  render() {
    return (
      <section className={styles.events}>
        <Title title="our" subtitle="events" />

        <div className={styles.center}>
          {this.state.sortedEvents.map(({ node }) => {
            return <Event key={node.contentful_id} event={node} />
          })}
        </div>
      </section>
    )
  }
}
