import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import EventList from "./EventList"
const Events = () => {
  const { events } = useStaticQuery(graphql`
    {
      events: allContentfulPmqaEvents{
        edges {
          node {
            name
            price
            slug
            journey {
              day
              info
            }
            country
            contentful_id
            days
            images {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  return <EventList events={events} />
}

export default Events
